<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="通知类型">
              <a-input v-model="queryParam.searchValue" allow-clear placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.type" placeholder="请选择" allow-clear>
                <a-select-option v-for="(item, index) in typeDictTypeDropDown" :key="index" :value="item.code">{{
                  item.value
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-icon type="setting" theme="filled" />
      <a-button type="primary" @click="$refs.addForm.add()">新建</a-button>
    </div>
    <s-table
      ref="table"
      size="default"
      :columns="columns"
      :data="loadData"
      :alert="true"
      :rowKey="(record) => record.id"
      :scroll="{x: 1200}"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
    >
      <span slot-scope="text, record, index" slot="numIndex">
        {{ index + 1 }}
      </span>
      <span slot="status" slot-scope="text" >
        {{ statusFilter(text) }}
      </span>
      <span slot="noticeType" slot-scope="text">
        {{ text == 1 ? '集体通知' : '个人通知'}}
      </span>
      <div slot="content" slot-scope="text" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
        {{ text }}
      </div>
       <span slot="userNames" slot-scope="text,record">
         {{ record.noticeType == 1 ? record.orgNames : record.userNames}}
      </span>
      <!--      <span slot="noticeType" slot-scope="text, record">
        {{ text == 1 ? record.orgNames : record.userNames}}
      </span> -->
      <span slot="action" slot-scope="text, record">
        <div v-if="record.status == 0">
          <a @click="$refs.editForm.edit(record)">修改</a>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="topRight"
            title="确认删除该信息？"
            @confirm="() => editNoticeStatus(3, record)"
          >
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="topRight"
            title="确认发送该信息？"
            @confirm="() => editNoticeStatus(1, record)"
          >
            <a>发送</a>
          </a-popconfirm>
        </div>
        <div v-if="record.status == 1">
          <a-popconfirm
            placement="topRight"
            title="确认撤回该信息？"
            @confirm="() => editNoticeStatus(0, record)"
          >
            <a>撤回发送</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a  @click="$refs.detailForm.detail(record)">详情</a>
        </div>
      </span>
    </s-table>
    <add-form ref="addForm" @ok="handleOk" />
    <edit-form ref="editForm" @ok="handleOk"/>
    <detail-form ref="detailForm" @ok="handleOk"/>
    <div ref="editor"></div>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import { sysNoticePage, sysNoticeDelete, sysNoticeChangeStatus } from '@/api/modular/system/noticeManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import addForm from './addForm'
import editForm from './editForm'
import detailForm from './detailForm'
export default {
  components: {
    STable,
    addForm,
    editForm,
    detailForm,
  },
  data() {
    return {
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          dataIndex: 'num',
          scopedSlots: { customRender: 'numIndex' },
          width: 80,
        },
        {
          title: '标题',
          dataIndex: 'title',
          width: 160
        },
        {
          title: '通知类型',
          dataIndex: 'noticeType',
          scopedSlots: { customRender: 'noticeType' },
          width: 100
        },
        {
          title: '通知对象',
          dataIndex: 'userNames',
          scopedSlots: { customRender: 'userNames' },
          width: 280
        },
        {
          title: '通知内容',
          dataIndex: 'content',
          scopedSlots: { customRender: 'content' },
          width: 500
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          width: 180
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 120
        },
        {
          title: '操作',
          width: '220px',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        return sysNoticePage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      statusDictTypeDropDown: [], // 0草稿 1发布 2撤回 3删除
      typeDictTypeDropDown: [], // 0通知 1公告
    }
  },
  created() {
    this.sysDictTypeDropDown() // 先注释
  },
  methods: {
    /**
     * 获取字典数据
     */
    sysDictTypeDropDown() {
      sysDictTypeDropDown({ code: 'notice_status' }).then((res) => {
        this.statusDictTypeDropDown = res.data
      })
      sysDictTypeDropDown({ code: 'notice_type' }).then((res) => {
        this.typeDictTypeDropDown = res.data
      })
    },
    statusFilter(status) {
      // eslint-disable-next-line eqeqeq
      const values = this.statusDictTypeDropDown.filter((item) => item.code == status)
      if (values.length > 0) {
       return values[0].code == '0'? '未发送':'已发送'
      }
    },
    typeFilter(type,record) {
      // eslint-disable-next-line eqeqeq
      console.log(type,record);
      // const values = this.typeDictTypeDropDown.filter((item) => item.code == type)
       if (record.noticeType =1) {
        return record.rgNames
       }else{
         return  record.userNames
       }
    },
    /**
     * 修改状态
     */
    editNoticeStatus(code, record) {
      sysNoticeChangeStatus({ id: record.id, status: code.toString() }).then((res) => {
        if (res.success) {
          this.$message.success('操作成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('操作失败：' + res.message)
        }
      })
    },
    /**
     * 提交
     */
    sysNoticeDelete(record) {
      sysNoticeDelete(record)
        .then((res) => {
          if (res.success) {
            this.$message.success('删除成功')
            this.$refs.table.refresh()
          } else {
            this.$message.error('删除失败：' + res.message)
          }
        })
        .catch((err) => {
          this.$message.error('删除错误：' + err.message)
        })
    },
    handleOk() {
      this.$refs.table.refresh()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  },
}
</script>
<style lang="less">
.table-operator {
  margin-bottom: 18px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
button {
  margin-right: 8px;
}
</style>
