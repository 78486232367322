<template>
  <a-modal title="新增通知公告" :width="1000" :footer="null" :visible="visible" @cancel="handleCancel">
    <a-spin :spinning="formLoading">
      <vue-scroll :ops="ops" style="width: 100%; height: 600px; margin-top: 12px">
        <a-form :form="form">
          <a-form-item label="标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
              placeholder="请输入标题"
              v-decorator="['title', { rules: [{ required: true, message: '请输入标题！' }] }]"
            />
          </a-form-item>
          <a-form-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-radio-group
              v-decorator="['type', { rules: [{ required: true, message: '请选择类型！' }] }]"
              v-model="type"
            >
              <a-radio-button v-for="(item, index) in typeDictTypeDropDown" :key="index" :value="item.code">{{
                item.value
              }}</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            label="通知对象单位"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            v-if="type == 'public_notice'"
          >
            <a-select v-model="orgId" placeholder="请选择通知对象单位" @change="selectOrg" mode="multiple">
              <a-select-option v-for="(item, index) in orgList" :key="index" :value="item.id">{{
                item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="通知对象" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="type == 'personal_notice'">
            <a-tree
              v-model="checkedKeys"
              multiple
              checkable
              :expanded-keys="expandedKeys"
              :auto-expand-parent="autoExpandParent"
              :selected-keys="selectedKeys"
              :replace-fields="replaceFields"
              :tree-data="treeData"
              @expand="onExpand"
              @select="onSelect"
            />
          </a-form-item>
          <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="内容">
            <!-- <antd-editor
              :uploadConfig="editorUploadConfig"
              v-model="editorContent"
              @onchange="changeEditor"
              @oninit="getEditor"
            /> -->
            <textarea
              v-model="editorContent"
              placeholder="请输入通知内容"
              style="width: 100%; height: 300px; padding: 10px; outline: none"
            ></textarea>
          </a-form-item>
          <!-- <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="通知到的人">
            <a-transfer
              :data-source="mockData"
              show-search
              :list-style="{
                width: '40%',
                height: '300px',
              }"
              :filter-option="filterOption"
              :target-keys="targetKeys"
              :render="(item) => item.title"
              @change="handleChange"
            />
          </a-form-item> -->
          <a-divider />
          <a-form-item class="subForm-item">
            <!-- <a-button type="primary" class="subButton" @click="handleSubmit('1')">发布</a-button> -->
            <!-- <a-button type="danger" class="subButton" @click="handleSubmit('0')">存为草稿</a-button> -->
            <a-button type="primary" class="subButton" @click="saveNotice">保存</a-button>
            <a-button class="subButton" @click="handleCancel">取消</a-button>
          </a-form-item>
        </a-form>
      </vue-scroll>
    </a-spin>
  </a-modal>
</template>
<script>
import { sysNoticeAdd, sysOrgList } from '@/api/modular/system/noticeManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { uploadFile } from '@/api/modular/mallLiving/uploadFile'
import { AntdEditor } from '@/components'
import { sysUserSelector, getUnitUserList } from '@/api/modular/system/userManage'
import { findOrgInfoList } from '@/api/modular/system/unitManage'
export default {
  name: 'AddForm',
  components: {
    AntdEditor,
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: '#ccc', //滚动条颜色
          opacity: 0.5, //滚动条透明度
          'overflow-x': 'hidden',
        },
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      orgId: [],
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      editorContent: '',
      editorContentText: '',
      editorUploadConfig: {
        method: 'http',
        callback: this.editorUploadImage,
      },
      mockData: [],
      treeData: [],
      targetKeys: [],
      typeDictTypeDropDown: [], // 0通知 1公告
      formLoading: true,
      orgList: [],
      orgUserList: [],
      userIds: [],
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      replaceFields: {
        children: 'childrenList',
        key: 'id',
        value: 'id',
        title: 'title',
      },
    }
  },
  created() {
    this.getAllOrgList()
    this.sysOrgList()
  },
  methods: {
    sysOrgList() {
      sysOrgList().then((res) => {
        if (res.success) {
          this.treeData = res.data.data.filter((item) => {
            return item.childrenList[0] != null
          })
          this.treeData.forEach((item) => {
            this.expandedKeys.push(item.id)
          })
        }
      })
    },
    editorUploadImage(files, insert) {
      files.forEach((file) => {
        uploadFile(file.name, file, {
          progress: function (p, checkpoint) {
            var percentage = Math.floor(p * 100)
          },
        }).then((res) => {
          if (res.res.statusCode == 200) {
            var list = res.res.requestUrls[0].split('?')
            if(list.length > 0){
              insert(list[0])
            }
          }
        })
      })
    },
    getEditor(editor) {
      this.editor = editor
    },
    changeEditor(html, ele) {
      this.editorContent = html
      this.editorContentText = ele.text()
    },
    selectOrg(value) {
      this.orgId = value
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onCheck(checkedKeys) {
      //console.log('onCheck', checkedKeys)
      this.checkedKeys = checkedKeys
    },
    onSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys
    },

    async getAllOrgList() {
      let data = await findOrgInfoList()
      if (data.code == 200) {
        this.orgList = data.data
      }
    },

    // 初始化方法
    add() {
      this.visible = true
      this.sysDictTypeDropDown() // 先注释
      this.getMock()
    },
    /**
     * 获取字典数据
     */
    sysDictTypeDropDown() {
      sysDictTypeDropDown({ code: 'notice_type' }).then((res) => {
        this.typeDictTypeDropDown = res.data
      })
    },

    getEditor(editor) {
      this.editor = editor
    },
    changeEditor(html, ele) {
      this.editorContent = html
      this.editorContentText = ele.text()
    },
    /**
     * 穿梭框
     */
    getMock() {
      const targetKeys = []
      const mockData = []
      sysUserSelector().then((res) => {
        this.formLoading = false
        for (let i = 0; i < res.data.length; i++) {
          const data = {
            key: res.data[i].id.toString(),
            title: res.data[i].name,
            description: `description of ${res.data[i].name}`,
          }
          mockData.push(data)
        }
      })
      this.mockData = mockData
      this.targetKeys = targetKeys
    },
    filterOption(inputValue, option) {
      return option.description.indexOf(inputValue) > -1
    },
    handleChange(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys
    },
    handleSubmit(types) {
      const {
        form: { validateFields },
      } = this
      //eslint-disable-next-line eqeqeq
      if (this.editorContent == '') {
        this.$message.error('请填写内容')
        return
      }
      if (this.targetKeys.length < 1) {
        this.$message.error('请选择通知到的人')
        return
      }
      validateFields((errors, values) => {
        if (!errors) {
          this.formLoading = true
          values.content = this.editorContent
          values.status = types
          values.noticeUserIdList = this.targetKeys
          sysNoticeAdd(values)
            .then((res) => {
              console.log(res)
              if (res.success) {
                this.$message.success('新增成功')
                this.$emit('ok', values)
                this.handleCancel()
              } else {
                this.$message.error('新增失败：' + res.message)
              }
            })
            .finally((res) => {
              this.formLoading = false
            })
        }
      })
    },
    handleCancel() {
      this.targetKeys = []
      this.editorContent = ''
      this.form.resetFields()
      this.visible = false
      this.formLoading = true
    },

    async confirmAddNotice(values) {
      this.formLoading = true
      let data = await sysNoticeAdd(values)
      if (data.code == 200) {
        this.$message.success('新增成功')
        this.$emit('ok', values)
        this.handleCancel()
        this.formLoading = false
      } else {
        this.$message.error('新增失败：' + data.message)
        this.formLoading = false
      }
    },
    async saveNotice() {
      let that = this
      const {
        form: { validateFields },
      } = this
      if (this.editorContent == '') {
        this.$message.error('请填写内容')
        return
      }
      console.log(this.checkedKeys)
      validateFields((errors, values) => {
        if (!errors) {
          if (this.type == 'personal_notice' && this.checkedKeys.length == 0) {
            this.$message.error('请选择通知对象')
            return
          }
          if (this.type == 'public_notice' && this.orgId.length == 0) {
            this.$message.error('请选择通知单位')
            return
          }
          values.content = this.editorContent

          if (this.type == 'personal_notice') {
            values.noticeUserIds = this.checkedKeys.length > 0 ? this.checkedKeys.join(',') : ''
            values.orgIds = ''
          } else {
            values.orgIds = this.orgId.length > 0 ? this.orgId.join(',') : ''
            values.noticeUserIds = ''
          }
          values.noticeType = this.type == 'public_notice' ? 1 : 2
          this.confirmAddNotice(values)
        }
      })
    },
  },
}
</script>
<style>
.subButton {
  float: right;
}
.subForm-item {
  margin-bottom: 0px;
}
</style>
