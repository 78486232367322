/*
 * @Description: 
 * @Version: 
 * @Author: 陈莉莉
 * @Date: 2021-09-30 09:03:21
 */
import { axios } from '@/utils/request'

/**
 * @description: 获取单位分页列表
 * @param {*} parameter
 * @return {*}
 * @author: 陈莉莉
 * @Date: 2021-10-06 16:10:32
 */
export function getUnitList(parameter) {
  return axios({
    url: '/sysOrg/orgPage?pageSize='+parameter.pageSize+'&pageNo='+parameter.pageNo,
    method: 'post',
    data: parameter
  })
}

/**
 * @description: 单位添加
 * @param {*} parameter
 * @return {*}
 * @author: 陈莉莉
 * @Date: 2021-10-06 16:10:21
 */
export function addUnit(parameter) {
  return axios({
    url: '/sysOrg/orgAdd',
    method: 'post',
    data: parameter
  })
}

/**
 * @description: 单位编辑
 * @param {*} parameter
 * @return {*}
 * @author: 陈莉莉
 * @Date: 2021-10-06 16:10:11
 */
export function editUnit(parameter) {
  return axios({
    url: '/sysOrg/orgEdit',
    method: 'post',
    data: parameter
  })
}

/**
 * @description: 省市区级联
 * @param {*} parameter
 * @return {*}
 * @author: 陈莉莉
 * @Date: 2021-10-06 16:09:58
 */
export function areaTree(parameter) {
  return axios({
    url: '/sysDistrict/areaTree',
    method: 'post',
    data: parameter
  })
}

/**
 * @description: 单位删除
 * @param {*} parameter
 * @return {*}
 * @author: 陈莉莉
 * @Date: 2021-10-06 16:09:35
 */
export function deleteUnit(parameter) {
  return axios({
    url: '/sysOrg/orgDelete',
    method: 'post',
    data: parameter
  })
}

/**
 * @description: 单位查询
 * @param {*}
 * @return {*}
 * @author: 陈莉莉
 * @Date: 2021-10-06 16:11:17
 */
export function findOrgList(parameter){
  return axios({
    url: '/sysOrg/orgInfo',
    method: 'post',
    data: parameter
  })
}

/**
 * @description: 单位名称查询
 * @param {*} parameter
 * @return {*}
 * @author: 陈莉莉
 * @Date: 2021-10-06 16:12:18
 */
export function findOrgInfoList(parameter){
  return axios({
    url: '/sysOrg/orgInfoOne',
    method: 'post',
    data: parameter
  })
}