<template>
  <a-modal title="编辑通知公告" :width="1000" :footer="null" :visible="visible" @cancel="handleCancel">
    <a-spin :spinning="formLoading">
       <vue-scroll :ops="ops" style="width: 100%; height: 600px; margin-top: 12px">
      <a-form :form="form">
        <a-form-item v-show="false">
          <a-input v-decorator="['id']" />
        </a-form-item>
        <a-form-item label="标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
            placeholder="请输入标题"
            v-decorator="['title', { rules: [{ required: true, message: '请输入标题！' }] }]"
          />
        </a-form-item>

        <a-form-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <!-- <a-radio-group
            :default-value="type"
            v-decorator="['noticeType', { rules: [{ required: true, message: '请选择类型！' }] }]"
            v-model="type"
            disabled="true"
          >
            <a-radio-button v-for="(item, index) in typeDictTypeDropDown" :key="index" :value="item.code">{{
              item.value
            }}</a-radio-button>
          </a-radio-group> -->
          <div>{{type == 1 ? '集体通知' : '个人通知' }}</div>
        </a-form-item>

        <a-form-item label="通知对象单位" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="type == '1'">
          <a-select
            v-model="orgId"
            placeholder="请选择通知对象单位"
            @change="selectOrg"
            mode="multiple"
          >
            <a-select-option v-for="(item, index) in orgList" :key="index" :value="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="通知对象" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="type == '2'">
          <!-- <a-input
            placeholder="请输入通知对象"
            v-decorator="['nofiyobject', { rules: [{ required: true, message: '请输入通知对象！' }] }]"
          /> -->
          <a-tree
            v-model="checkedKeys"
            multiple
            checkable
            :expanded-keys="expandedKeys"
            :auto-expand-parent="autoExpandParent"
            :selected-keys="selectedKeys"
            :replace-fields="replaceFields"
            :tree-data="treeData"
            @expand="onExpand"
            @select="onSelect"
          />
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="内容">
          <textarea
            v-model="editorContent"
            placeholder="请输入通知内容"
            
            style="width: 100%; height: 300px; padding: 10px; outline: none"
          ></textarea>
        </a-form-item>

        <a-divider />
        <a-form-item class="subForm-item">
          <!-- <a-button type="primary" class="subButton" @click="handleSubmit('1')">发布</a-button> -->
          <a-button type="danger" class="subButton" :loading="loading" @click="handleSubmit">保存</a-button>
          <a-button class="subButton" @click="handleCancel">取消</a-button>
        </a-form-item>
      </a-form>
       </vue-scroll>
    </a-spin>
  </a-modal>
</template>
<script>
import { sysNoticeEdit, sysNoticeDetail, sysOrgList } from '@/api/modular/system/noticeManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { uploadFile } from '@/api/modular/mallLiving/uploadFile'
import { AntdEditor } from '@/components'
import { findOrgInfoList } from '@/api/modular/system/unitManage'
import { sysUserSelector } from '@/api/modular/system/userManage' 
export default {
  name: 'AddForm',
  components: {
    AntdEditor,
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: '#ccc', //滚动条颜色
          opacity: 0.5, //滚动条透明度
          'overflow-x': 'hidden',
        },
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      loading: false,
      visible: false,
      orgList: [],
      orgId: [],
      defaultList: [],
      treeData: [],
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      replaceFields: {
        children: 'childrenList',
        key: 'id',
      },
      form: this.$form.createForm(this),
      typeDictTypeDropDown: [], // 0通知 1公告
      editorContent: '',
      editorUploadConfig: {
        method: 'http',
        callback: this.editorUploadImage,
      },
      mockData: [],
      targetKeys: [],
      noticeDetail: [],
      formLoading: false,
      type: ''
    }
  },
  created() {
    this.getAllOrgList()
    this.sysOrgList()
  },
  methods: {
    // 初始化方法
    edit(record) {
      console.log(record)
      this.defaultList = record.orgNames
      this.visible = true
      this.sysDictTypeDropDown()
      this.type = record.noticeType
      this.editorContent = record.content
      if(record.noticeType == 1){
        this.orgId = record.noticeOrgIds ? record.noticeOrgIds.split(',') : []
      }else if(record.noticeType == 2){
        this.checkedKeys = record.noticeUserIds ? record.noticeUserIds.split(',') : []
      }
      console.log(record)
      setTimeout(() => {
        this.form.setFieldsValue({
          id: record.id,
          title: record.title,
          noticeType: record.noticeType,
          content: record.content,
          orgNames: record.orgNames,
          userNames: record.userNames,
        })
      }, 1000)
    },

    editorUploadImage(files, insert) {
      files.forEach((file) => {
        uploadFile(file.name, file, {
          progress: function (p, checkpoint) {
            var percentage = Math.floor(p * 100)
          },
        }).then((res) => {
          if (res.res.statusCode == 200) {
            var list = res.res.requestUrls[0].split('?')
            if(list.length > 0){
              insert(list[0])
            }
          }
        })
      })
    },
    getEditor(editor) {
      this.editor = editor
    },
    changeEditor(html, ele) {
      this.editorContent = html
      this.editorContentText = ele.text()
    },

    onExpand(expandedKeys){
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onselect(e, info){

    },
    
    async getAllOrgList() {
      let data = await findOrgInfoList()
      if (data.code == 200) {
        this.orgList = data.data
      }
    },
    selectOrg(value) {
      this.orgId = value
    },
    /**
     * 获取字典数据
     */
    sysDictTypeDropDown() {
      sysDictTypeDropDown({ code: 'notice_type' }).then((res) => {
        this.typeDictTypeDropDown = res.data
        this.typeDictTypeDropDown.forEach(item =>{
          item.code = item.code == 'personal_notice' ? 2 : 1
        })
        this.typeDictTypeDropDown = JSON.parse(JSON.stringify( this.typeDictTypeDropDown))
      })
    },
    getEditor(editor) {
      this.editor = editor
    },
    sysOrgList() {
      sysOrgList().then((res) => {
        console.log(res)
        if (res.success) {
          this.treeData = res.data.data.filter((item) => {
            return item.childrenList[0] != null
          })
          this.treeData.forEach((item) => {
            this.expandedKeys.push(item.id)
          })
        }
      })
    },
    /**
     * 穿梭框
     */
    getMock(noticeDetail) {
      const targetKeys = []
      const mockData = []
      sysUserSelector().then((res) => {
        this.formLoading = false
        for (let i = 0; i < res.data.length; i++) {
          const data = {
            key: res.data[i].id.toString(),
            title: res.data[i].name,
            description: `description of ${res.data[i].name}`,
          }
          for (let j = 0; j < noticeDetail.noticeUserIdList.length; j++) {
            if (data.key === noticeDetail.noticeUserIdList[j]) {
              targetKeys.push(noticeDetail.noticeUserIdList[j])
            }
          }
          mockData.push(data)
        }
      })
      this.mockData = mockData
      this.targetKeys = targetKeys
    },
    filterOption(inputValue, option) {
      return option.description.indexOf(inputValue) > -1
    },
    handleChange(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys
    },
    handleSubmit(type) {
      const {
        form: { validateFields },
      } = this
      // eslint-disable-next-line eqeqeq
      if (this.editorContent == '') {
        this.$message.error('请填写内容')
        return
      }
      validateFields((errors, values) => {
        if (!errors) {
          if (this.type == 'personal_notice' && this.checkedKeys.length == 0) {
            this.$message.error('请选择通知对象')
            return
          }
          if (this.type == 'public_notice' && this.orgId.length == 0) {
            this.$message.error('请选择通知单位')
            return
          }
          values.content = this.editorContent
          if (this.type == '2') {
            values.noticeUserIds = this.checkedKeys.length > 0 ? this.checkedKeys.join(',') : ''
            values.orgIds = ''
          } else {
            values.orgIds = this.orgId.length > 0 ? this.orgId.join(',') : ''
            values.noticeUserIds = ''
          }
          values.noticeType = this.type
          this.confirmAddNotice(values)
        }
      })
    },

    confirmAddNotice(values){
      console.log(values)
      this.formLoading = true
      sysNoticeEdit(values).then(res =>{
        this.formLoading = false
        if(res.success){
          this.$message.success('新增成功')
          this.$emit('ok', values)
          this.handleCancel()
        }
      }).catch(err =>{
        this.formLoading = false
      })
    },

    handleCancel() {
      this.targetKeys = []
      this.editorContent = ''
      this.form.resetFields()
      this.visible = false
      this.formLoading = false
    },
  },
}
</script>
<style>
.subButton {
  float: right;
}
.subForm-item {
  margin-bottom: 0px;
}
</style>
